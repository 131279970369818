.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar::before,
.bar::after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background-color: #124df9;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar::before {
  left: 50%;
}

.bar::after {
  right: 50%;
}

.textArea {
  border-style: none;
  background-color: #fafafa;
  border-bottom: 2px solid rgb(241, 241, 241);
  border-radius: 5px 5px 0 0;
  display: block;
  font-size: 14px;
  padding: 16px;
  outline: none;
  width: 100%;
  height: 85px;
  resize: none;
  color: #212121;
}

.textArea:focus {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.tag {
  color: #606469;
  left: 15px;
  position: absolute;
  pointer-events: none;
  top: 15px;
  font-size: 14px;
  margin-bottom: 5px;
}

.textArea:focus ~ .tag {
  color: #124df9;
}

.textArea:focus ~ .tag,
.textArea:valid ~ .tag {
  font-size: 12px;
  top: 1px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2 ease all;
  -o-transition: 0.2 ease all;
}

/* active state */
.textArea:focus ~ .bar:before,
.textArea:focus ~ .bar:after {
  width: 50%;
}
