.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar::before,
.bar::after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background-color: #124df9;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar::before {
  left: 50%;
}

.bar::after {
  right: 50%;
}

.textBox {
  background-color: #fafafa  !important;
  border-bottom: 2px solid rgb(241, 241, 241);
  border-radius: 5px 5px 0 0 !important;
  display: block;
  font-size: 14px;
  height: 56px;
  padding: var(--padding-top, 16px) var(--padding-right, 16px)
    var(--padding-bottom, 0px) var(--input-padding-left, 16px) !important;
  outline: none !important;
  width: 100% !important;
  bottom: 1px !important;
  box-shadow: none !important;
  border-color: transparent;
}

.textBox.invalid,
.bar.invalid,
.invalid {
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
  animation: shake 0.2s ease-in-out 0s 2;
}

.textBox:focus {
  outline: none;
}

.tag {
  color: #606469;
  left: 15px;
  position: absolute;
  pointer-events: none;
  top: 15px;
  font-size: 14px;
  margin-bottom: 5px;
}

.textBox:focus ~ .tag {
  color: #124df9;
}

/* activate state */
.textBox:disabled:not(:placeholder-shown) ~ .tag,
.textBox:focus ~ .tag,
.textBox:valid ~ .tag {
  font-size: 12px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2 ease all;
}

/* active state */
.textBox:focus ~ .bar:before,
.textBox:focus ~ .bar:after {
  width: 50%;
}

.bar.invalid::before,
.bar.invalid::after {
  width: 50%;
  background-color: #f44336;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
