@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mv-quickview-gap: 13rem;
  --mv-table-gap: 4rem;
  --mv-window-gap: 4.375rem;
}

* {
  font-family: 'Aeonik', ui-sans-serif, system-ui, '-apple-system',
    'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

@layer base {
  @font-face {
    font-family: 'Aeonik';
    src: url('/assets/fonts/aeonik/Aeonik-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'AeonikMedium';
    src: url('/assets/fonts/aeonik/Aeonik-Medium.otf') format('opentype');
  }
}

@layer utilities {
  .border-spacing-0 {
    border-spacing: 0;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .button-disabled {
    @apply opacity-25 pointer-events-none;
  }

  .inputToggleButtonUnchecked {
    box-shadow: 0px -4px 8px 0px #64748b1a inset;
  }

  .inputToggleButtonChecked {
    box-shadow: 2px 0px 4px 0px #00000040 inset;
  }

  /* react-phone-number-input */
  .PhoneInput .PhoneInputCountry {
    border: 1px solid rgb(226 232 240 / var(--tw-border-opacity));
    padding: 0.2rem 1rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-right: 0;
    height: 42px;
  }

  .PhoneInput .PhoneInputInput {
    border: 1px solid rgb(226 232 240 / var(--tw-border-opacity));
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    height: 42px;
  }
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-editor {
  min-height: 7rem;
}

.ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bg-primary-active {
  background-color: #f2f6ff;
}

.open-menu {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: rotate(-180deg);
}

.close-menu {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: rotate(0deg);
}

.drawer-open {
  width: 240px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}

.drawer-close {
  width: 80px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}

.expand-item-drawer {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.9s cubic-bezier(0, 0, 0.2, 1);
}

.collapsed-item-drawer {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.primary-icon {
  fill: #606469;
  width: 20px;
  height: 20px;
}

.color-primary-active {
  fill: #0000ff;
}

.color-neutrals-inactive {
  fill: #e8eaec;
}

.bg-primary-avatar {
  background-color: #e6ecfe;
}

.active-primary-avatar {
  color: #124df9;
}

.h-70 {
  height: var(--mv-window-gap);
}

.h-content-container {
  height: calc(100% - var(--mv-window-gap));
}

.h-container {
  height: calc(100% - var(--mv-table-gap));
}

.quickview-container {
  height: calc(100% - var(--mv-quickview-gap));
}

.min-h-80 {
  min-height: 5rem;
}

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}

/* login */
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

/* Spinner */
.loader {
  border-top-color: #124df9;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}

/* Hide number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.fc .fc-popover {
  z-index: 1;
}
