.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar::before,
.bar::after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background-color: #124df9;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar::before {
  left: 50%;
}

.bar::after {
  right: 50%;
}

.textBox {
  background-color: #fafafa !important;
  border-bottom: 2px solid rgb(241, 241, 241);
  border-radius: 5px 5px 0 0 !important;
  display: block;
  font-size: 14px !important;
  height: 56px !important;
  padding: 16px 16px 0px 16px !important;
  outline: none !important;
  width: 100%;
  bottom: 1px;
  border: none !important;
}

.textBox:focus {
  outline: none;
  border: none;
}

.tag {
  color: #606469;
  left: 15px;
  position: absolute;
  pointer-events: none;
  top: 15px;
  font-size: 14px;
  margin-bottom: 5px;
}

.textBox:focus ~ .tag {
  color: #124df9;
}

/* activate state */
.textBox:disabled:not(:placeholder-shown) ~ .tag,
.textBox:focus ~ .tag,
.textBox:valid ~ .tag {
  font-size: 12px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2 ease all;
}

/* active state */
.textBox:focus ~ .bar:before,
.textBox:focus ~ .bar:after {
  width: 50%;
}


.bar.invalid::before,
.bar.invalid::after {
  width: 50%;
  background-color: #f44336;
}
