.inputCheckbox:checked,
.inputCheckbox:checked:hover,
.inputCheckbox:focus,
.inputCheckbox:checked:focus {
    border-color: theme('colors.primary-60');
    @apply bg-transparent;
}

.inputCheckbox:not(:checked){
    @apply border-neutrals-60;
}

.inputCheckbox:disabled,
.inputCheckbox:disabled:hover {
    @apply border-neutrals-40;
}

.inputCheckbox:disabled ~ .inputCheckboxIcon svg path {
    fill: theme('colors.neutrals-40');
}

.inputCheckbox:disabled ~ .inputCheckboxIcon {
    @apply pointer-events-none;
}

.inputCheckbox:checked ~ .inputCheckboxIcon {
    @apply opacity-100;
}